import * as React from "react";
import styled from "styled-components";

const roadmapData = [
    {
        image: "/img/kwif1.jpeg",
        phase: "Hamster Jam Session 🎸",
        description: `A kangaroo crashing a pizza 🍕 party in a market, dressed to the nines like it's about to give a speech! 🦘 This roo's got more swagger 🎩 than a pizza delivery guy on roller skates!`,
    },
    {
        image: "/img/kwif2.jpeg",
        phase: "Rockstar Status 🌟",
        description: `Meet Kangaroo the Magnificent! 🎩✨ His magic is so fast, he makes Peanut Butter sandwiches 🥪vanish before you finish saying 'Abracadabra!' Houdini's sweating, folks—Kangaroo's hops are no joke! Hop-hop-hooray for the great peanut butter disappearing act!"😋`,
    },
    {
        image: "/img/kwif3.jpeg",
        phase: "Hamster Celeb Status 🌟🎩",
        description: `Kangaroo Gala: 🦘One's dressed to kill, 🕺🏻the other's shaking like a milkshake machine!😂 Picture James Bond moonwalking🌚 with a top hat and monocle while the other roo boogies like it's its last hop! The grass is rolling with laughter.🤣`,
    },
    {
        image: "/img/kwif4.jpeg",
        phase: "Hamster Domination 🚀🌎",
        description: `Kangaroo DJ dropping beats like they're hot potatoes! 🎶 Crowd's bouncing like they're on kangaroo steroids, 🪩 beats got roos breakdancing like they're auditioning for 'Kangaroo's Got Talent,' 🕺🏻 and it's a roo-tiful disco inferno under rainbow lights! 🌈`,
    },
    {
        image: "/img/kwif5.jpeg",
        phase: "Hamster Domination 🚀🌎",
        description: `Kangaroo suited up like a Wall Street banker!👔 Lounging in the grass with CEO vibes. 😎 Another roo's puzzled: 'Is that a kangaroo 🦘or a kangarudebega?' 😂 Must be the power of the pinstripes!`,
    },
    {
        image: "/img/kwif6.jpeg",
        phase: "Hamster Domination 🚀🌎",
        description: `🦘 a kangaroo rocking a black top hat and bow tie, striking a pose like it just won a kangaroo talent show. 🎩 The trees in the background are gossiping like, 🌳"Did you see that stylish marsupial? 🕺🏻What's next, a kangaroo opera?`,
    }
];

function MyComponent() {
    return (
        <RoadmapSection id='roadmap'>
            <RoadmapTitle>Meme-mania</RoadmapTitle>
            {/* <RoadmapDescription>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with the release
                of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions
                of Lorem Ipsum.
            </RoadmapDescription> */}
            <RoadmapPhases>
                {roadmapData.map((phase, index) => (
                    <RoadmapPhase key={index}>
                        <Overlay />
                        <RoadmapPhaseImage
                            src={phase.image}
                            alt={`Phase ${index + 1} illustration`}
                        />
                        <RoadmapPhaseDescription>
                            {phase.description}
                        </RoadmapPhaseDescription>
                    </RoadmapPhase>
                ))}
            </RoadmapPhases>
        </RoadmapSection>
    );
}

const RoadmapSection = styled.section`
margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 1028px) {
    margin-top: 70px;
  }
`;

const RoadmapTitle = styled.h2`
  margin-bottom: 0px;
  color: #fff;
  align-self: center;
  font: 400 60px 'Spicy Soup Regular';
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const RoadmapDescription = styled.p`
  text-align: start;
  align-self: center;
  color: var(--Font-color, #fff);
  margin-top: 47px;
  width: 60%;
  font: 400 18px K2D, sans-serif;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
  }
`;

const RoadmapPhases = styled.div`
  margin-top: 69px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
    max-width: 100%;
    margin-top: 40px;
    align-content: center;
  }
`;

const RoadmapPhase = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 500px;
//   flex-grow: 1;
  width: 35%;
  align-items: start;
  color: #fff;
  font-weight: 400;
  border-radius: 15px;
  justify-content: flex-end;
  padding: 50px 30px 50px 30px;
//   width: calc(50% - 10px);
@media (max-width: 1028px) {
  width: 40%;
}
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
    width: 75%;
    min-height: 550px;
  }
  @media (max-width: 468px) {
    padding: 20px 20px 0px;
    min-height: 400px;
    width: 80%;
  }
`;

const RoadmapPhaseImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;

`;

const Overlay = styled.div`
width: 100%;
height: 100%;
z-index: 999;
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
`;

const RoadmapPhaseTitle = styled.h3`
  position: relative;
  margin: 0px;
  font: 50px Zeyada, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const RoadmapPhaseDescription = styled.p`
z-index: 99999;
  position: relative;
//   margin-top: 39px;
  font: 20px/37px K2D, sans-serif;
  @media (max-width: 468px) {
  font: 16px / 22px K2D, sans-serif;
  }
  & ul {
    list-style-type: disc; /* Change bullet style */
    margin-left: 0px; /* Add left margin */
    padding-left: 20px
  }
  & ul li {
    text-align: start; /* Align text to the start */
  }
`;

export default MyComponent;
import React, { useState } from "react";
import styled from "styled-components";
import { smartcontractAddress, etherscanurl } from '../../url';
import Tooltip from './Tooltip';

const CryptoAddressContainerMainDiv = styled.div`
position: relative;
align-self: center !important;
@media (max-width: 468px) {
  margin-top: 30px
}
`;

const CryptoAddressContainer = styled.div`
  border-radius: 50px;
  border: 3px solid rgba(0, 212, 253, 1);
  background-color: var(--Button-hover, #005e71);
  display: flex;
  gap: 10px;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  padding: 16px 22px;
  width: 95%;
  justify-content: center;
  @media (max-width: 1028px) {
    padding: 16px 12px;
    width: 95%;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 7px 10px;
    width: 100%;
  }
  @media (max-width: 468px) {
  padding: 7px 10px;
  width: 95%;
  gap: 5px;
  }
`;

const CryptoAddressText = styled.div`
cursor: pointer;
  font-family: 'Spicy Soup Regular';
  // flex-grow: 1;
//   flex-basis: auto;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
   font-size: 12px
    }
`;

const CryptoAddressIcon = styled.img`
cursor: pointer;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  @media (max-width: 468px) {
    width: 12px;
  }
`;

function CryptoAddress() {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAddressClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide tooltip after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleAddressClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };
  return (
    <CryptoAddressContainerMainDiv>
      {isCopied && <Tooltip>Copied!</Tooltip>}
      <CryptoAddressContainer>
        <CryptoAddressText onClick={() => handleAddressClick(etherscanurl)}>
          <span style={{ color: "rgba(0,212,253,1)" }}>$KWIF :</span>{" "}
          {smartcontractAddress}
        </CryptoAddressText>
        <CryptoAddressIcon style={{ zIndex: 99999 }} onClick={() => handleCopyAddressClick(smartcontractAddress)} loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/30588826f67636c4b9ffbfe1c5fd5f19f7d531c064a16ab4dee22eb4963d8703?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Crypto Address Icon" />
      </CryptoAddressContainer>
    </CryptoAddressContainerMainDiv>
  );
}

export default CryptoAddress;
import React, { useState } from 'react'
import './App.css'
import HeaderSection from './components/HeaderSection/HeaderSection'
import HeroSection from "./components/HeroSection/HeroSection";
import MemeCoinSection from "./components/MemeCoinSection/MemeCoinSection";
import BuyTokenSection from "./components/BuyTokenSection/BuyTokenSection";
import Tokenomics from "./components/TokenomicsSection/TokenomicsSection";
import FooterSection from "./components/FooterSection/FooterSection";
import FunnyImageSection from './components/FunnyImageSection/FunnyImageSection'

function App() {

  return (
    <>
      <HeaderSection />
      <HeroSection />
      <MemeCoinSection />
      <FunnyImageSection />
      <BuyTokenSection />
      <Tokenomics />
      <FooterSection />
    </>
  )
}

export default App
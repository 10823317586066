import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import CryptoAddress from './WalletAddressButton';
import { smartcontractAddress, uniswapurl } from '../../url';

const bounceAnimation = keyframes`
  0%{
    transform: translateY(0px) scale(1);
  }
  50% {
    transform: translateY(70px) scale(1);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
`;

function MyComponent() {
  const [animate, setAnimate] = useState(false);

  const handleMouseEnter = () => {
    setAnimate(true);
  };

  const handleMouseLeave = () => {
    // Delay setting animation to false to allow the animation to complete
    setTimeout(() => {
      setAnimate(false);
    }, 500); // Delay should match the transition duration
  };

  // const handleClick = () => {
  //   setAnimate(true);
  //   setTimeout(() => {
  //     setAnimate(false);
  //   }, 2000); // Duration of the animation
  // };

  const handleExploreClick = () => {
    window.open(uniswapurl, '_blank');
    // const tokenSection = document.getElementById('whatiskwif');
    // if (tokenSection) {
    //   tokenSection.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  return (
    <MainSection>
      <ContentWrapper>
        <ContentColumn>
          <ContentContainer>
            <Title>
              <TitleSpan>Welcome to $KWIF</TitleSpan>   <br />
              Meme World
            </Title>
            {/* <CustomButtonHover onClick={handleExploreClick}>
              <FirstSpan>Click!</FirstSpan>
              <SecondSpan>Buy Now</SecondSpan>
            </CustomButtonHover> */}
            <ExploreButton onClick={handleExploreClick}>Buy Now</ExploreButton>
            {smartcontractAddress && (
              <CryptoAddress />
            )}
          </ContentContainer>
        </ContentColumn>
        <ImageColumn>
          <HeroImage animate={animate} onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave} src="https://cdn.builder.io/api/v1/image/assets/TEMP/a14630e415f84d002725e2c8444ad092965c1cb2de188fa58349b5f829badb40?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Hero Image" />
        </ImageColumn>
      </ContentWrapper>
    </MainSection>
  );
}

const MainSection = styled.section`
  margin-top: 0px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  @media (max-width: 468px) {
min-height: 100vh;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  @media (max-width: 1028px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 400;
  margin: auto 0;
  padding: 0 20px 0 150px;
  @media (max-width: 1028px) {
    padding: 0 20px 0 50px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px 0 20px;
  }
  @media (max-width: 468px) {
    align-items: center;
    margin-top: 0px;
  }
`;

const Title = styled.h1`
  color: var(--Font-color, #fff);
  font: 60px 'Spicy Soup Regular';
  text-align: start;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px; 
    text-align: center;
  }
  @media (max-width: 468px) {
    text-align: center;
  }
`;

const TitleSpan = styled.span`
  color: #00D4FD;
`;


const CustomButton = styled.button`
font: 20px 'Spicy Soup Regular';
  position: relative;
  left: 0px;
  top: 0px;
  border: none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  margin-top: -35px;
  margin-bottom: 60px;
  -webkit-perspective: 230px;
  perspective: 230px;
  background: transparent;
  color: #ffffff;
  // overflow: hidden;
`;

const ButtonSpan = styled.span`
  background: linear-gradient(0deg, #00d4fd 0%, #035d71 100%);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  // box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5),
  //            7px 7px 20px 0px rgba(0,0,0,.1),
  //            4px 4px 5px 0px rgba(0,0,0,.1);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
`;

const FirstSpan = styled(ButtonSpan)`
  // box-shadow: -7px -7px 20px 0px #fff9,
  //            -4px -4px 5px 0px #fff9,
  //            7px 7px 20px 0px #0002,
  //            4px 4px 5px 0px #0001;
  transform: rotateX(90deg);
  transform-origin: 50% 50% -20px;
`;

const SecondSpan = styled(ButtonSpan)`
  transform: rotateX(0deg);
  transform-origin: 50% 50% -20px;
`;

const CustomButtonHover = styled(CustomButton)`
  &:hover ${FirstSpan} {
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5),
               7px 7px 20px 0px rgba(0,0,0,.1),
               4px 4px 5px 0px rgba(0,0,0,.1);
    transform: rotateX(0deg);
  }

  &:hover ${SecondSpan} {
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5),
               7px 7px 20px 0px rgba(0,0,0,.1),
               4px 4px 5px 0px rgba(0,0,0,.1);
    color: transparent;
    transform: rotateX(-90deg);
  }
`;

const ExploreButton = styled.button`
cursor: pointer;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--Button-color, #fff);
  align-self: start;
  margin-top: 0px;
  margin-bottom: 50px;
  color: #000;
  padding: 10px;
  font: 24px 'Spicy Soup Regular';
  @media (max-width: 991px) {
    align-self: center!important;
  }
  @media (max-width: 468px) {
    font: 20px 'Spicy Soup Regular';
    margin-bottom: 0px;
    align-self: center!important;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  position: relative;
  @media (max-width: 1028px) {
    width: 40%;
  }
  @media (max-width: 991px) {
    width: 85%;
    align-items: center;
  }
`;

const HeroImage = styled.img`
  aspect-ratio: 0.85;
  object-fit: cover;
  object-position: center;
  width: 80%;
  flex-grow: 1;
  transform: translate(0px, 0px) scale(1);
  z-index: 9999; // Adjust the z-index value as needed
  animation: ${({ animate }) => animate ? css`${bounceAnimation} 2s ease-in-out infinite alternate` : 'none'};
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 90%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    width: 70%;
    margin-top: 40px;
  }
`;

export default MyComponent;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/SpicySoup-MVKLP.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/spicy-soup);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  width: 100%;
  background: #041C21;
}

@font-face {
  font-family: 'Spicy Soup Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Spicy Soup Regular'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}

.iframDiv {
  width: 100%;
}

.hemiLeft {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,mBAAmB;AACrB;;AAIA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,mBAAmB;EACnB,wFAAoF;AACtF;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,UAAU;AACZ","sourcesContent":["#root {\n  max-width: 100%;\n  margin: 0px;\n  padding: 0px;\n  text-align: center;\n  width: 100%;\n  background: #041C21;\n}\n\n@import url('https://fonts.cdnfonts.com/css/spicy-soup');\n\n@font-face {\n  font-family: 'Spicy Soup Regular';\n  font-style: normal;\n  font-weight: normal;\n  src: local('Spicy Soup Regular'), url('./fonts/SpicySoup-MVKLP.woff') format('woff');\n}\n\n.iframDiv {\n  width: 100%;\n}\n\n.hemiLeft {\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

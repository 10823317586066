import * as React from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, linktreeurl, uniswapurl, dextool } from '../../url';

const data = [
  {
    id: 1,
    image: "/img/uniswap.png",
    url: uniswapurl
  },
  {
    id: 1,
    image: "/img/twitter.png",
    url: twitterurl
  },
  {
    id: 2,
    image: "/img/telegram.png",
    url: telegramurl
  },
  {
    id: 3,
    image: "/img/Dextool.png",
    url: dextool
  },
  {
    id: 3,
    image: "/img/linktree.png",
    url: linktreeurl
  }
];

const menuItems = [
  { label: "What is $KWIF", link: "#whatiskwif" },
  { label: "Buy Token", link: "#buytoken" },
  { label: "Tokenomics", link: "#tokenomics" },
];

function Header() {

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  const scrollToSection = (e, link) => {
    e.preventDefault();
    const section = document.querySelector(link);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <HeaderWrapper>
      <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/17dd0f0d2158c2bea24ae7de17e09cf2ec59597d7cd60edb3299530fb0f74e17?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Logo" />
      <Nav>
        {menuItems.map((item, index) => (
          <NavItem key={index}>
            <NavLink href={item.link} onClick={(e) => scrollToSection(e, item.link)}>{item.label}</NavLink>
          </NavItem>
        ))}
      </Nav>
      {/* <SocilMediaSection> */}
        <SocialIcons>
          {data.map((item) => (
            <Image onClick={() => handleSocialLinkClick(item.url)} src={item.image} alt="footer image" />
          ))}
        </SocialIcons>
      {/* </SocilMediaSection> */}
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  font-size: 24px;
  color: var(--Primary-Color, #00d4fd);
  font-weight: 400;
  @media (max-width: 1028px) {
    padding: 10px 0px;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Logo = styled.img`
  width: 136px;
  max-width: 100%;
  aspect-ratio: 2.22;
  object-fit: contain;
  padding-left: 10px;
  @media (max-width: 991px) {
    width: 20%;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 70px;
  margin-left: 150px;
  width: 60%;
  @media (max-width: 1028px) {
    gap: 40px;
    margin-left: 0px;
    width: 55%;
    justify-content: center;
  }
  @media (max-width: 991px) {
    width: 75%;
    margin-top: 20px;
    gap: 30px;
    margin-left: 0px;
    // justify-content: flex-end;
  }
  @media (max-width: 468px) {
  margin-top: 0px;
  gap: 12px;
}
`;

const NavItem = styled.li`
  list-style: none;
`;

const NavLink = styled.a`
  font-family: 'Spicy Soup Regular';
  text-decoration: none;
  color: inherit;
  @media (max-width: 468px) {
    font-size: 14px;
  }
`;

const SocialIcons = styled.div`
cursor: pointer;
display: flex;
align-items: center;
margin: 0px 20px 0px;
@media (max-width: 991px) {
  margin: 20px 0px 0px;
}
@media (max-width: 468px) {
    margin: 20px 20px 20px;
}
`;

const Image = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 20px;
  max-width: 100%;
  margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};
  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
  &:first-child {
    width: 30px;
    height: 30px;
  }
`;

export default Header;
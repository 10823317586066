import * as React from "react";
import styled from "styled-components";

function MemeExplanation() {
    return (
        <ExplanationSection id="whatiskwif">
            <Heading>What is $KWIF?</Heading>
            <Description>
                In the realm of cryptocurrencies, Kwif Coin emerged as a whimsical phenomenon with its kangaroo-in-a-hat mascot, captivating internet jokesters and curious investors alike. This meme-inspired token swiftly gained value, sparking joyful celebrations and virtual meetups within its dedicated "Kwif Krew" community. However, its fortunes swung wildly like a caffeinated kangaroo, epitomizing the unpredictable nature of crypto markets. Despite the rollercoaster ride, Kwif Coin's legacy endures as a testament to the playful and unpredictable spirit of online culture and meme-driven investments.
            </Description>
            <MemeImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/870f33b2b1bdb28046e22e221271c49f5baf42385f65987173923f46e98d0aef?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Meme coin illustration" />
        </ExplanationSection>
    );
}

const ExplanationSection = styled.section`
margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 0 20px;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
  }
`;

const Heading = styled.h2`
  font: 60px 'Spicy Soup Regular';
  margin-bottom: 0px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px;
  }
`;

const Description = styled.p`
  text-align: center;
  width: 60%;
  font: 18px Istok Web, sans-serif;
  line-height: 25px;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
  }
`;

const MemeImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 432px;
  margin-top: 37px;
  max-width: 100%;
  @media (max-width: 1028px) {
    width: 300px;
  }
  @media (max-width: 468px) {
    width: 200px;
    margin-top: 30px;
  }
`;

export default MemeExplanation;
import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { uniswapurl } from "../../url";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const beatAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

function HowToBuyOnUniswap() {
  const [rotating, setRotating] = useState(false);

  const handleImageClick = () => {
    setRotating(true);
    setTimeout(() => {
      window.open(uniswapurl, '_blank');
      setRotating(false);
    }, 1000); // Redirect after 2 seconds (duration of one rotation)
  };

  return (
    <Container id="buytoken">
      <Title>How to buy on Uniswap?</Title>
      <Description>
        To buy Kwif Coin on Uniswap, you'll first need to ensure you have a compatible cryptocurrency like Ethereum (ETH) in a digital wallet such as MetaMask or Trust Wallet. Start by accessing the Uniswap exchange platform and connecting your wallet. Next, navigate to the trade interface and click on "Select a Token." If Kwif Coin (KWIF) isn't automatically displayed, you may need to input its contract address to add it as a custom token. Once Kwif Coin is selected, specify the amount of ETH you want to swap for KWIF. Review and confirm the transaction details, taking note of gas fees. After confirming the swap, wait for the transaction to process, and voila! You've successfully acquired Kwif Coin on Uniswap. Remember to exercise caution when trading cryptocurrencies and do your research on the project before investing.
      </Description>
      <ContentWrapper>
        {/* <WebViewWrapper>
                    <IframWrapper>
                        <iframe
                            width="100%"
                            height="660px"
                            src={uniswapurl}
                        ></iframe>
                    </IframWrapper>
                </WebViewWrapper> */}
        <ImageWrapper>
          <Tooltip>Click on me to buy token from Uniswap</Tooltip>
          <Image rotating={rotating}
            onClick={handleImageClick}
            loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6728436b8885db4baee7b2d1295725dbae78b3f6e2e5bef0f8f061fe47dcf04f?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Web view screenshot" />
        </ImageWrapper>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 1028px) {
    padding: 0px;
  }
`;

const Title = styled.h1`
  color: var(--Font-color, #fff);
  font: 400 60px 'Spicy Soup Regular';
  
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px
  }
`;

const Description = styled.p`
  color: var(--Font-color, #fff);
  text-align: center;
  margin-top: 27px;
  width: 60%;
  font: 400 18px Istok Web, sans-serif;
  line-height: 25px;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-self: stretch;
  margin-top: 44px;
  width: 100%;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

const WebViewWrapper = styled.div`
align-items: center;
  display: flex;
  width: 55%;
  margin-left: 0;
  justify-content: center;
  @media (max-width: 1028px) {
width: 100%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const IframWrapper = styled.div`
align-items: center;
  display: flex;
  width: 660px;
  @media (max-width: 1028px) {
width: 660px;
  }
  @media (max-width: 468px) {
    width: 88%;
      }

`;

const WebViewText = styled.div`
  border-radius: 10px;
  background-color: #86afc6;
  align-self: stretch;
  align-items: center;
  color: #000;
  justify-content: center;
  width: 100%;
  margin: auto 0;
  padding: 235px 60px 80px;
  font: 400 60px Spicy Soup, sans-serif;
  
  @media (max-width: 991px) {
    font-size: 40px;
    max-width: 100%;
    margin-top: 40px;
    padding: 40px 20px 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  position: relative;
  align-items: center;
  @media (max-width: 1028px) {
    // display: none;
   }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Image = styled.img`
margin-top: 30px;
  animation: ${({ rotating }) => (rotating ? css`${rotate360} 1s linear` : 'none')};
  object-fit: contain;
  object-position: center;
  width: 80%;
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 80%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Tooltip = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 14px;
  width: fit-content;
  align-self: center;
  font: 400 30px 'Spicy Soup Regular';
  animation: ${beatAnimation} 2s infinite alternate; 
  /* Add arrow */
  &::before {
    content: '';
    position: absolute;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 20px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  @media (max-width: 468px) {
    width: 70%;
    font: 400 16px 'Spicy Soup Regular';
  }
`;


export default HowToBuyOnUniswap;
import * as React from "react";
import styled from "styled-components";

function Tokenomics() {
    return (
        <TokenomicsSection id="tokenomics">
            <TokenomicsTitle>Tokenomics</TokenomicsTitle>
            <TokenomicsImage
                loading="lazy"
                src="/img/Piechart.png"
                alt="Tokenomics chart"
            />
        </TokenomicsSection>
    );
}

const TokenomicsSection = styled.section`
margin-top: 50px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 20px;
  color: var(--Font-color, #fff);
  font-size: 60px;
  font-weight: 400;
  align-items: center;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const TokenomicsTitle = styled.h2`
  font-family: 'Spicy Soup Regular';
  align-self: center;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const TokenomicsImage = styled.img`
  width: 60%;
  aspect-ratio: 1.75;
  object-fit: auto;
  object-position: center;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    margin-top: 0px;
  }
`;

export default Tokenomics;